import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

import InlineBodyTransform from '../inlinebodytransform';
import {Link} from 'gatsby';
import React from 'react';

const happeningsTable = (props) => {

    let searchbody = (str, length) => {

        if ((str===null) || (str===''))
            return false;
        else
        str = str.toString().replace(/<[^>]*>/g, '')
        str = (str.match(RegExp(".{"+length+"}\\S*"))||[str])[0]
        str = str + " [ See More...]"
    
        return str;
    }

    return (
        <MDBCol xs="12" md="12" lg="12" className="col-12" style={{minHeight: "150px"}}>
            <MDBRow>
                <MDBCol xs="2" md="2" lg="2" sm="2" className="col-2 py-3 text-center">
                    <Link to={props.link}>
                        <MDBIcon icon="far fa-arrow-alt-circle-right" size="3x" className="red-text-Genesis center py-0" />
                    </Link>
                </MDBCol>
                <MDBCol xs="10" md="10" lg="10" sm="10" className="col-10 py-3">
                    <Link to={props.link} style={{fontSize: "0.8em"}}>
                        <h3 className="d-block">{props.title}</h3>
                        {props.body ? <InlineBodyTransform bodySearch={searchbody(props.body, 200)} /> 
                    : ""}</Link>
                </MDBCol>
            </MDBRow>
      </MDBCol>
    )
}

export default happeningsTable;

