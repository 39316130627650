import { MDBCard, MDBCol, MDBIcon, MDBRow } from 'mdbreact';

import React from 'react';
import styled from 'styled-components';

// Search component
const SearchFilter = (props) => {
  return (
    <MDBCol xs="12" md="12" lg="12" className="col-12">
      <MDBCard className="my-2 py-2 px-3">
        <MDBRow>
          <MDBCol xs="2" md="2" lg="2" sm="2" className="col-2 py-3 text-center">
            <MDBIcon icon="fa fa-fas fa-search" size="3x" className="red-text-Genesis" />
          </MDBCol>
          <MDBCol xs="10" md="10" lg="10" sm="10" className="col-10 p-10">
            <label htmlFor="fpages" className="red-text-Genesis">Search Page</label>
            <InputComp 
                label="fpages"
                outline size="sm"
                value={props.query}
                onChange={props.onCV}
                className="form-control red-text-Genesis"
                id="fpages"/>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBCol>
  )
}

export default SearchFilter;

const InputComp = styled.input`
  :focus{
    border-color: #ee1C25!important;
    box-shadow: 0 0 0 0.2rem #ffc3c3!important;
  }
`;