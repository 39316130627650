import { Card, MDBCol } from 'mdbreact';

import PageRow from './pagerow';
import React from 'react';

// Search component
const Search = (props) => {
    return (
        <MDBCol xs="12" md="12" lg="12" className="col-12">
            <Card className="my-2 p-1" style={{minHeight: "490px"}}>
                {props.results.map(page => {
                    return(
                        <PageRow 
                            key={page.id}
                            title={page.title}
                            body={page.body ? page.body: null}
                            link={page.path}
                        />)
                })}
            </Card>
        </MDBCol>
    )
}

export default Search;