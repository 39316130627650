import { MDBContainer, MDBRow } from 'mdbreact'
import React, { useState } from "react"

import { Index } from 'elasticlunr'
import PageResults from './pageresults'
import SearchFilter from './searchfilter'

const SearchList = (props) => {
  const [query, setQuery] = useState(``);
  const [results, setResults] = useState([]);

  const getOrCreateIndex = (index, searchIndex) =>
    index
      ? index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(searchIndex)

  const search = (evt) => {
    let index = getOrCreateIndex(index, props.searchIndex)
    setQuery(evt.target.value);
    setResults(index
      .search(evt.target.value, { expand: true }) // Accept partial matches
      // Map over each ID and return the full document
      .map(({ ref }) => index.documentStore.getDoc(ref))
    )
  }

  return (
    <MDBContainer tag="section">
      <MDBRow center>
        <SearchFilter query={query} onCV={search} />
        <PageResults results={results} />
      </MDBRow>
    </MDBContainer>
  )

}

export default SearchList