import { MDBContainer, MDBRow } from 'mdbreact';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import Search from "../components/search/searchlist";

const SearchPage = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
        <Layout>
        <SEO 
          title={`Search Results | Genesis`} 
          description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
          keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
        />
        <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow className="my-sm-2 my-md-4">
            <Search searchIndex={data.siteSearchIndex.index} />
          </MDBRow>
        </MDBContainer>
      </main>
      </Layout>
    )}
  />
)

export default SearchPage